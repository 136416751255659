.landing-page {
	color: black;
	background-size: cover;
	background-position: -100%;
	width: 100%;
	min-height: calc(90vh - 100px);
	display: flex;
	flex-direction: column;
	align-items: stretch;

	.hero-section {
		flex: 1 1 auto;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.details {
			max-width: 400px;

			.sub {
				margin: 20px 0;
			}
		}

		.hero-image {
			width: 75%;
			padding: 20px;
			max-width: 750px;

			img {
				width: 100%;
				display: block;
				opacity: 0.8;
			}
		}
	}

	#features {
	}
}

@media only screen and (max-width: 991px) {
	.landing-page {
		.hero-section {
			flex-direction: column-reverse;
			.details {
				width: 100%;
				padding: 0 10px 40px 10px;
			}

			.hero-image {
				width: 100%;
				max-width: 500px;
			}
		}
	}
}
