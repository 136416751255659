.site-select-dropdown {
	.dropdown-selector {
		width: 100%;

		.max {
			max-width: 200px;
		}

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
