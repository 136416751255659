// IMPORTS

// BOOTSTRAP FOR CUSTOM COMPONENTS
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

// MAIN

html,
body {
	font-family: "Raleway", sans-serif !important;
	margin: 0;
	overflow-x: hidden;
}

.number {
	font-family: "Roboto", sans-serif !important;
}

.vs {
	font-size: 0.6rem;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.header-space {
	padding-top: 60px;
}

* {
	box-sizing: border-box;
}

*:not(input, button, a) {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.page-width {
	max-width: 1400px;
	width: 100%;
	margin: auto;
}

.flex-space {
	flex: 1 1 auto;
}

.img-cont {
	img {
		width: 100%;
		display: block;
	}
}

a {
	transition-delay: 0s;
	transition-duration: 0s;
	text-decoration: none;
	color: inherit;

	&:hover {
		transition-delay: 0s;
		transition-duration: 0s;
		color: inherit;
	}
}

.slick-track {
	display: flex;
}
.slick-track .slick-slide {
	display: flex;
	height: auto;
}

.recharts-tooltip-wrapper {
	z-index: 1000 !important;
}

// BOOTSTRAP OVERRIDES + UPGRADES

.modal {
	--bs-modal-width: 700px;
}

.spin {
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.interact {
	cursor: pointer;
}

.btn.btn-icon:hover {
	opacity: 0.8;
}

.btn.btn-icon,
.accordion-button,
input:focus,
input:hover {
	outline: none !important;
	box-shadow: none !important;
}

.card {
	&:hover {
		&.white-tran {
			transition-duration: 0.25s;
			background-color: white !important;
			border-color: white !important;
		}

		&.hover-shadow {
			transition-duration: 0.25s;
			box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.5) !important;
		}
	}
}

.gap-x-1 {
	gap: 0 0.25rem !important;
}

.gap-x-2 {
	gap: 0 0.5rem !important;
}

.gap-x-3 {
	gap: 0 1rem !important;
}

.gap-x-4 {
	gap: 0 1.5rem !important;
}

.gap-x-4 {
	gap: 0 3rem !important;
}

.tiny {
	font-size: 0.6em;
}

.flex-fill-even {
	flex: 1 1 0;
}

.h-100 {
	height: 100%;
}

.pg-100 {
	min-height: 100vh;
}

.w-33 {
	width: 33.3%;
}

.w-66 {
	width: 66.6%;
}

.w-content {
	width: fit-content;
}

.opacity-01 {
	opacity: 0.01;
}

.opacity-05 {
	opacity: 0.05;
}

.opacity-1 {
	opacity: 0.1;
}

.opacity-2 {
	opacity: 0.2;
}

.opacity-3 {
	opacity: 0.3;
}

.opacity-4 {
	opacity: 0.4;
}

.opacity-5 {
	opacity: 0.5;
}

.opacity-6 {
	opacity: 0.6;
}

.opacity-7 {
	opacity: 0.7;
}

.opacity-8 {
	opacity: 0.8;
}

.opacity-9 {
	opacity: 0.9;
}

// XXL
@media only screen and (max-width: 1400px) {
	.xxl-100 {
		width: 100% !important;
	}

	.xxl-75 {
		width: 75% !important;
	}

	.xxl-50 {
		width: 50% !important;
	}

	.xxl-25 {
		width: 25% !important;
	}
}

// XL
@media only screen and (max-width: 1200px) {
	.xl-100 {
		width: 100% !important;
	}

	.xl-75 {
		width: 75% !important;
	}

	.xl-50 {
		width: 50% !important;
	}

	.xl-25 {
		width: 25% !important;
	}
}

// LG
@media only screen and (max-width: 992px) {
	.lg-100 {
		width: 100% !important;
	}

	.lg-75 {
		width: 75% !important;
	}

	.lg-50 {
		width: 50% !important;
	}

	.lg-25 {
		width: 25% !important;
	}
}

// MD
@media only screen and (max-width: 768px) {
	.md-100 {
		width: 100% !important;
	}

	.md-75 {
		width: 75% !important;
	}

	.md-50 {
		width: 50% !important;
	}

	.md-25 {
		width: 25% !important;
	}
}

// SM
@media only screen and (max-width: 576px) {
	.sm-100 {
		width: 100% !important;
	}

	.sm-75 {
		width: 75% !important;
	}

	.sm-50 {
		width: 50% !important;
	}

	.sm-25 {
		width: 25% !important;
	}
}

// TEMPLATE

// XXL
@media only screen and (max-width: 1400px) {
}

// XL
@media only screen and (max-width: 1200px) {
}

// LG
@media only screen and (max-width: 992px) {
}

// MD
@media only screen and (max-width: 768px) {
}

// SM
@media only screen and (max-width: 576px) {
}
