@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$sidenav_min_width: 64px;
$sidenav_item_height: 64px;
$sidenav_max_width: 250px;
$sidenav_open_time: 0.5s;
$sidenav_close_time: 0.25s;
$sidenav_header_height: 50px;

.sidenav-push {
	margin-left: $sidenav_min_width;
}

.sidenav-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.4);

	&.open {
		display: block;
	}

	&.closed {
		display: none;
	}
}

.sidenav {
	width: 100%;
	// overflow: hidden;
	position: fixed;
	height: 100%;
	z-index: 1000;

	.nav-header {
		.nav-content {
			height: $sidenav_header_height;
			display: flex;
			align-items: center;
		}
	}

	.sidenav-toggle {
		display: none;
		position: absolute;
		margin-left: 0.5rem;
		left: $sidenav_min_width;
		top: calc($sidenav_header_height / 2);
		z-index: 10000;
		cursor: pointer;

		button {
			appearance: none;
			background: transparent;
			outline: none;

			width: 28px;
			height: 28px;
			padding: 0;
		}
	}

	.nav-content {
		margin-left: 0.5rem;
		flex: 1 1 auto;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.inner {
			width: 100%;

			span {
				line-height: 33px;
				height: 33px;
				display: block;
			}
		}
	}

	.nav-item,
	.header-nav-item {
		transition-delay: 0s !important;
		transition-duration: 0s !important;
		padding: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		&:not(.no-overlay):hover {
			cursor: pointer;
			transition-delay: 0s !important;
			transition-duration: 0s !important;
			background-color: $blue-100;
		}

		&:not(.no-hover):hover,
		&.active {
			transition-delay: 0s !important;
			transition-duration: 0s !important;
			background-color: $blue-100;
		}

		&.active,
		&:not(.no-hover).active:hover {
			background-color: $blue-200;
		}
	}

	.nav-item {
		height: $sidenav_item_height;
	}

	.header-nav-item {
		padding: 0;
	}

	&.open {
		transition-duration: $sidenav_open_time;
		max-width: $sidenav_max_width;
		box-shadow: 4px 4px 4px rgb(0 0 0 / 30%);

		.sidenav-toggle {
			transition-duration: $sidenav_open_time;
			left: $sidenav_max_width;
		}

		.nav-group {
			.group-name {
				text-align: left;
			}
		}

		.nav-item,
		.header-nav-item {
			transition-delay: $sidenav_open_time;
			justify-content: space-between;
		}

		.nav-content {
			transition-delay: $sidenav_close_time;
			transition-duration: $sidenav_close_time;
			opacity: 1;
			max-width: 100%;
			height: $sidenav_item_height;
			// overflow-x: hidden;
		}

		.nav-content .inner {
			transition-delay: 0;
			transition-duration: 0;
			display: block;
		}
	}

	&:not(.open) {
		transition-delay: $sidenav_close_time;
		transition-duration: $sidenav_close_time;
		max-width: $sidenav_min_width;

		.sidenav-toggle {
			transition-delay: $sidenav_close_time;
			transition-duration: $sidenav_close_time;
			left: $sidenav_min_width;
		}

		.nav-group {
			.group-name {
				text-align: center;
			}
		}

		.nav-item,
		.header-nav-item {
			width: 100%;
			justify-content: center;
		}

		.nav-content {
			transition-duration: $sidenav_close_time;
			opacity: 0;
			max-width: 0;
			margin: 0;
			height: $sidenav_item_height;
			overflow: hidden;
		}

		.nav-content .inner {
			display: none;
		}
	}
}

.i_device {
	.nav-footer {
		margin-bottom: 85px;
	}
}

// XXL
@media only screen and (max-width: 1400px) {
}

// XL
@media only screen and (max-width: 1200px) {
}

// LG
@media only screen and (max-width: 992px) {
}

// MD
@media only screen and (max-width: 768px) {
}

// SM
@media only screen and (max-width: 576px) {
}
